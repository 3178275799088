import { useIntersect, useFont } from "@react-three/drei";
import { useFrame, useThree, useLoader } from "@react-three/fiber";
import React, { useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three-stdlib";

//Objects and stuff
import HEX_MODEL from "../../Assets/Models/hex3D.glb";

const Item = ({ color, position, children }) => {
  const visible = useRef();
  const ref = useIntersect((isVisible) => (visible.current = isVisible));
  const [xRandomFactor, yRandomFactor] = useMemo(
    () => [(0.25 - Math.random()) * 0.25, (0.25 - Math.random()) * 0.25],
    []
  );

  useFrame(({ clock }, delta) => {
    const elapsedTime = clock.getElapsedTime();

    ref.current.rotation.x = elapsedTime * xRandomFactor;
    ref.current.rotation.y = elapsedTime * yRandomFactor;

    const scale = THREE.MathUtils.damp(
      ref.current.scale.x,
      visible.current ? 1.5 : 0.2,
      5,
      delta
    );
    ref.current.scale.set(scale, scale, scale);
  });

  return (
    <mesh ref={ref} position={position}>
      {children}
      <meshPhysicalMaterial transparent color={color} wireframe={true} />
    </mesh>
  );
};

export const Objects = () => {
  const [windowWidth] = useState(window.innerWidth);
  const { height, width } = useThree((state) => state.viewport);
  const hexModel = useLoader(GLTFLoader, HEX_MODEL);

  return (
    <>
      <pointLight color="blue" position={[8, -25, 5]} intensity={20} />
      <pointLight
        color="blue"
        position={[0, -height * 2.25, 5]}
        intensity={10}
      />
      <Item color="gray" position={[0, 1, 0]}>
        {windowWidth > 768 && (
          <mesh scale={2}>
            <primitive object={hexModel.scene} />
          </mesh>
        )}
      </Item>
      {/** */}
      <Item color="blue" position={[width / 6, -height * 1, 0]}>
        <dodecahedronGeometry />
      </Item>
    </>
  );
};

useFont.preload("../../Assets/Models/hex3D.glb");
