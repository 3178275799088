import { initializeApp } from "firebase/app";
import { getDatabase, ref, push } from "firebase/database";
import { getStorage, ref as StorageRef } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCm0suqURMdK0ae-flFy7G5F69eNXekpZI",
    authDomain: "hexstudio-io.firebaseapp.com",
    projectId: "hexstudio-io",
    storageBucket: "hexstudio-io.appspot.com",
    messagingSenderId: "452067974885",
    appId: "1:452067974885:web:0722c7b506f143bbd13e0c",
    measurementId: "G-13Z2YLE2R8"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const storage = getStorage(app);

export { db, push, ref, storage, StorageRef };