import { Page } from "./Components/Page"
import { Loader } from "./Components/Loader"
import { Header } from './Components/Header'

export const App = () => {
    return (
        <>
            <Loader />
            <main className="page">
                <Header />
                <Page />
            </main>
        </>
    )
}