import { useRef, useState } from "react";
import Globe from "react-globe.gl";
import globeJson from "../../Assets/countries.json";
import "./index.css";

import HEX_LOGO from "../../Assets/Icons/logo.jpg";
import { MeshBasicMaterial } from "three";

export const Earth = () => {
  const [rotate, setRotate] = useState(false);
  const [width] = useState(window.innerWidth);
  const earthRef = useRef();

  const earthReady = () => {
    if (earthRef.current && width > 768) {
      earthRef.current.controls().autoRotate = true;
      earthRef.current.controls().enableZoom = false;
    } else if (earthRef.current && width <= 768) {
      earthRef.current.controls().autoRotate = true;
      earthRef.current.controls().enableZoom = false;
    }
  };

  const handleGlobeClick = () => {
    setRotate(!rotate);
    if (earthRef.current && width > 768) {
      if (!rotate) {
        earthRef.current.controls().autoRotate = false;
      } else {
        earthRef.current.controls().autoRotate = true;
      }
    }
  };

  const locationData = [
    {
      city: "Toronto",
      lat: 43.6651611,
      lng: -79.4160864,
      altitude: 0.1,
      color: "rgba(255, 255, 255, 1)",
    },
    {
      city: "Vancouver",
      lat: 49.2578182,
      lng: -123.206304,
      altitude: 0.1,
      color: "#ffffff",
    },
    {
      city: "New York",
      lat: 40.6976312,
      lng: -74.1444874,
      altitude: 0.1,
      color: "#ffffff",
    },
    {
      city: "Seoul",
      lat: 37.5650337,
      lng: 126.8093324,
      altitude: 0.1,
      color: "#ffffff",
    },
    {
      city: "Bancroft",
      lat: 44.9072223,
      lng: -79.3328217,
      altitude: 0.1,
      color: "#ffffff",
    },
    {
      city: "Melbourne",
      lat: -37.9715652,
      lng: 144.7235026,
      altitude: 0.1,
      color: "#ffffff",
    },
    {
      city: "Austin",
      lat: 30.3079541,
      lng: -97.9205504,
      altitude: 0.1,
      color: "#ffffff",
    },
  ];

  return (
    <Globe
      ref={earthRef}
      globeMaterial={MeshBasicMaterial}
      hexPolygonsData={globeJson.features}
      hexPolygonColor={(geometry) => {
        return ["#0000ff", "#0000cc", "#000099", "#000066"][
          geometry.properties.abbrev_len % 4
        ];
      }}
      width={width > 768 ? 900 : 400}
      height={width > 768 ? 900 : 450}
      backgroundColor="rgba(255, 255, 255, 0)"
      onGlobeReady={earthReady}
      onGlobeClick={handleGlobeClick}
      htmlElementsData={locationData}
      htmlAltitude="altitude"
      htmlElement={(data) => {
        const { city, color } = data;
        const element = document.createElement("div");
        element.className = "earth-obj";
        element.style.color = color;
        element.innerHTML = `
          <div class='earth-tags'>
            <img src=${HEX_LOGO} />
            <strong style="font-size:10px;text-align:center">${city}</strong>
          </div>`;
        return element;
      }}
    />
  );
};
