import { useEffect, useState } from "react";
import sanityClient from "../../client";

import { Contact } from "../Contact";
import { Earth } from "../Earth";

import "./index.css";

export const Html = () => {
  const [introData, setIntroData] = useState([]);
  const [serviceData, setServicesData] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const fetchIntroData = async () => {
    try {
      const query = `*[_type == 'introBlock'][0]`;
      const result = await sanityClient.fetch(query);
      setIntroData(result.introContent);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchServicesData = async () => {
    try {
      const query = `*[_type == 'servicesBlock']`;
      const result = await sanityClient.fetch(query);

      setServicesData(result);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProjectsData = async () => {
    try {
      const query = `*[_type == 'projectsBlock']`;
      const result = await sanityClient.fetch(query);
      setProjectData(result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchIntroData();
    fetchServicesData();
    fetchProjectsData();
  }, []);

  return (
    <>
      <div className="intro-block">
        {introData && introData.title && introData.tagLine && (
          <>
            <h1>{introData.title}</h1>
            <h2>{introData.tagLine}</h2>
          </>
        )}
      </div>
      <div className="services-block">
        <h1> services </h1>
        <div className="services">
          {serviceData && serviceData.length > 0
            ? serviceData.map((service, index) => {
                return (
                  <>
                    <h2>{service.serviceTitle}</h2>
                    <ul>
                      {service.serviceList.map((serviceItem, index) => {
                        return <li key={index}>{serviceItem}</li>;
                      })}
                    </ul>
                  </>
                );
              })
            : null}
        </div>
      </div>
      <div className="projects-block">
        <div>
          <h1> work </h1>
          <div className="projects">
            <ul>
              {projectData.map((project, index) => {
                return (
                  <li key={index}>
                    <a href={project.url}>{project.title}</a>
                    <span>{project.timeline}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="earth-block">
          <h3>
            A visualization of the areas around the world our work has travelled
            to
          </h3>
          <Earth />
        </div>
      </div>
      <Contact />
    </>
  );
};
