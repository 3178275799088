import { Canvas } from "@react-three/fiber";
import { EffectComposer, Bloom, Noise } from "@react-three/postprocessing";
import { Scene } from '../Scene'

import './index.css';

export const Page = () => {
    return (
          <Canvas className="canvas">
            <ambientLight />
            <directionalLight color="white" intensity={10} />
            <Scene />
            <EffectComposer>
              <Bloom intensity={10} luminanceThreshold={0} luminanceSmoothing={0} height={1000} />
              <Noise opacity={1} />
            </EffectComposer>
          </Canvas>
    )
}