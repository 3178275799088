import { useEffect, useState } from "react";
import { onValue, ref, off } from "firebase/database";
import { db } from "../../firebase";

import './index.css';

export const Loader = () => {
    // const [quotes, setQuotes] = useState([]);
    // const [quote, setQuote] = useState({
    //     nickname: '',
    //     quote: ''
    // });
    const [isLoading, setIsLoading] = useState(true);

    // const getRandomQuote = () => {
    //     const randomIndex = Math.floor(Math.random() * quotes.length);
    //     const randomQuote = quotes[randomIndex];
    //     setQuote(randomQuote);
    // };

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, [isLoading]);

    // useEffect(() => {
    //     const hexQuotesRef = ref(db, "quotes");
    //     const fetchData = (snapshot) => {
    //         try {
    //             const data = snapshot.val();
    //             if (data) {
    //                 const quotesArray = Object.values(data);
    //                 setQuotes(quotesArray);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data from Firebase:", error);
    //         }
    //     };

    //     onValue(hexQuotesRef, fetchData);

    //     return () => {
    //         off(hexQuotesRef, fetchData);
    //     };
    // }, []);

    // useEffect(() => {
    //     if (quotes.length > 0) {
    //         getRandomQuote();
    //     }
    // }, [quotes]);

    return (
        <>
            <div className={`loader ${isLoading ? '' : 'hide'}`}>
                {/* {quote.quote !== "" && quote.nickname !== "" && (
                    <h1>"{quote.quote}" - {quote.nickname}</h1>
                )} */}
                <h1>{`<Give us a couple seconds while we set things up! />`}</h1>
            </div>
        </>
    );
};
