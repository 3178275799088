import { useState, useEffect } from "react";
import { push, ref } from "firebase/database";
import { db } from "../../firebase";
import BadWordsFilter from "bad-words";

import './index.css'

export const LoaderInput = ({ isFormOpen, setIsFormOpen }) => {
    const filter = new BadWordsFilter();
    const [nickname, setNickname] = useState("");
    const [quote, setQuote] = useState("");
    const [formText, setFormText] = useState('Contribute to our website by submitting a one liner which will be featured in our loading screen!');

    const handleNicknameChange = (e) => {
        setNickname(e.target.value);
        const isProfane = filter.isProfane(e.target.value);
        
        if (isProfane) {
            alert("Caught ya there! Hexstudio has faith in your naming prowess! Do better! 🌟")
            setNickname("");
        }
    };

    const handleQuoteChange = (e) => {
        setQuote(e.target.value);
        const isProfane = filter.isProfane(e.target.value);
        
        if (isProfane) {
            alert("Yupp it applies here too!")
            setQuote("");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let isValid = true;
        const errors = {};
    
        if (nickname.trim() === "") {
            isValid = false;
            errors.nickname = "Nickname is required";
        }

        if (quote.trim() === "") {
            isValid = false;
            errors.quote = "A single liner is required";
        }

        if (!isValid) {
            return (
                alert(
                    `There are following errors: ${
                        Object.keys(errors).map((err) => {
                            return errors[err]
                        })
                    }`
                )
            )
        } else {
            const userEntry = {
                nickname: nickname,
                quote: quote
            };

            const hexQuotesRef = ref(db, "quotes");
            push(hexQuotesRef, userEntry).then(() => {
                setNickname("");
                setQuote("");
                setFormText('Nice! On your next visit (or you can just refresh the browser) you might get a chance to see your submission.')
                setTimeout(() => {
                    setIsFormOpen(false);
                }, 4000);
            }).catch((err) => {
                console.log(err);
            })
        }
    };
    
    const handleCloseClick = (e) => {
        if (e.target.id === 'form-container') {
            setIsFormOpen(false);
        }
    }

    const handleEsc = () => {
        setIsFormOpen(false);
    }

    useEffect(() => {
        const canvas = document.querySelector('.canvas');
        
        if (isFormOpen) {
            canvas.classList.add('blur');
        } else {
            canvas.classList.remove('blur');
        }
    }, [isFormOpen]);

    return (
        <>
            <div id="form-container" className={isFormOpen ? 'open' : ''} onClick={handleCloseClick}>
                <form onSubmit={handleSubmit}>
                    <p className="close-icon" onClick={handleEsc}>x Esc</p>
                    <p>{formText}</p>
                    <label htmlFor="nickname">Nickname:</label>
                    <input type="text" id="nickname" value={nickname} onChange={handleNicknameChange} required />
                    <label htmlFor="quote">Quote:</label>
                    <input type="text" id="quote" value={quote} onChange={handleQuoteChange} required />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </>
    )
}