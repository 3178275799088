import { useState } from "react";
import { LoaderInput } from "../LoaderInput";
import './index.css';
import LOGO from '../../Assets/Icons/logo.jpg';

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [isFormOpen, setIsFormOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    const handleOptionClick = (e) => {
        setSelectedOption(e.target.innerText);

        if (e.target.dataset.option === 'loading-input-modal') {
            setIsFormOpen(true);
        }
        setIsOpen(false);
    }

    return (
        <>
            <header className='header'>
            </header>
        </>
    )
}